<template>
    <div>
        <v-row align="center" class="mb-3">
            <v-col cols="12" md="2">
                <v-btn @click="openAddDialog()" color="primary secondary--text" large class="btn">
                    <v-icon class="ml-2">add_circle_outline</v-icon>
                    <h3>إضافة {{ this.$route.meta.single }}</h3>
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="3">
                <v-text-field
                    v-debounce:500ms="getItems"
                    v-model="$global.state.filter.search"
                    label="ابحث هنا"
                    color="info"                
                    hide-details="auto"
                    prepend-inner-icon="search"
                    dense
                    outlined
                    filled
                />
            </v-col>
        </v-row>

        <v-card id="card">
            <v-data-table
                class="mytable"
                :items-per-page="$global.state.filter.pageSize"
                :items="$global.state.users"
                :loading="$global.state.loading"
                :headers="headers"
                hide-default-footer
                loading-text="جاري تحميل البيانات"
                no-data-text="لا توجد بيانات"
                :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">

                <template v-slot:item.id="{item}">
                   <v-avatar v-if="item.imageUrl" size="36">
                        <v-img :src="item.imageUrl" alt="zaid" width="90"></v-img>
                    </v-avatar>
                    <v-avatar v-else size="36" color="primary" class="white--text">
                        <span>{{ item.fullName.split(' ')[0].charAt(0) }}</span>
                        <span>{{ item.fullName.split(' ')[1] ? item.fullName.split(' ')[1].charAt(0) : "" }}</span>
                    </v-avatar>
                    <!-- <span>{{ $global.state.users.indexOf(item) + 1 }}</span> -->
                </template>

                <template v-slot:item.phoneNumber="{ item }">
                    <div dir="ltr">{{ item.phoneNumber != null ? (item.phoneNumber | phone) : '' }}</div>
                </template>

                <template v-slot:item.group="{item}">
                    <h5>{{ item.group.name }} </h5>
                    <h5 class="grey--text">{{ item.group.description }} </h5>
                </template>

                <template v-slot:item.actions="{ item, index }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                                <v-icon color="error"> delete </v-icon>
                            </v-btn>
                        </template>
                        <span>حذف</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="openEditDialog(item)" v-on="on" icon>
                                <v-icon color="info"> edit </v-icon>
                            </v-btn>
                        </template>
                        <span>تعديل</span>
                    </v-tooltip>

                   <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="toggleBlock(item)" v-on="on" icon>
                                <v-icon :color="item.isBlock ? 'grey darken-2' : 'red' ">
                                    {{ item.isBlock ? 'mdi-lock-open' : 'mdi-cancel' }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{item.isBlock ? 'إلغاء الحظر' : 'حظر' }}</span>
                    </v-tooltip> -->
                    
                </template>
            </v-data-table>
        </v-card>
        
        <Pagination />
        <Dialog />
    </div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog"),
    },
    data() {
        return {
            headers: [
                {
                    text: "#",
                    value: "id",
                },
                {
                    text: "الإسم الثلاثي",
                    value: "fullName",
                },
                {
                    text: "إسم المستخدم",
                    value: "userName",
                },
                {
                    text: "البريدالإلكتروني",
                    value: "email",
                },
                {
                    text: "رقم الهاتف",
                    value: "phoneNumber",
                    align: "right",
                },
                {
                    text: "الصلاحية",
                    value: "group",
                },
                {
                    text: "الإجراءات",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],
        };
    },

    mounted() {
        this.getItems();

        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(`get${this.$route.meta.endPoint}`);
        },

        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.$global.state.users.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: "add",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openRegionDialog(item) {
            item.dialogType = "add";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setRegionDialog");
            this.$eventBus.$emit("fill-fields");
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        },
    },
};
</script>
